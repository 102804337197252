var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "direct-flight-select justify-content-start ml-1 ml-md-0 justify-content-md-end mr-md-50 pb-25"
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-warning",
    attrs: {
      "switch": ""
    },
    on: {
      "input": function input(val) {
        return _vm.handleUpdate(val);
      }
    },
    model: {
      value: _vm.isDirectFlightOnly,
      callback: function callback($$v) {
        _vm.isDirectFlightOnly = $$v;
      },
      expression: "isDirectFlightOnly"
    }
  }, [_c('span', {
    staticClass: "text-body font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('flight.directFlightSelect')))])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }